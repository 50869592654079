import { useState, useEffect } from 'react';

import Spinner from './Spinner';
import { routes } from '../api';
import { timeToMMSS } from '../utils'; 


function QueueItem(props) {
    const onClick = (e) => {
        e.preventDefault();
        props.onSelectSong(props.songIndex);
    }

    const getArtists = () => {
        var ret = '';
        props.artists.forEach((a,i) => {
            ret = ret + (i > 0 ? ' & ' : '') + a.name;
        });
        return ret;
    }

    return (
        <tr className='text-secondary'
            onClick={onClick}
            >
            <td className='pl-3' style={{ verticalAlign: 'middle' }}>
                <img src={`${routes.static_base}/${props.album.artwork_url}`} className='border' alt='...' style={{ height: '35px', width: 'auto' }} />
            </td>
            <td className='' style={{ fontSize: '0.75rem', verticalAlign: 'middle' }}>
                <div className='text-dark fw-bold'>
                    {props.name}
                </div>
                <div>
                    {getArtists()} - {props.album.name}
                </div>
            </td>
            <td className='pr-3' style={{ fontSize: '0.85rem', verticalAlign: 'middle' }}>
                {timeToMMSS(props.duration)}
            </td>
        </tr>
    )
}

export default function SongQueue(props) {
    const [durations, setDurations] = useState([]);
    const [updateTimer, setUpdateTimer] = useState(null);

    const updateDurations = () => {
        if(!props.loading) {
            var audioElements = document.getElementsByTagName('audio');
            if(audioElements.length === 0 ){
                return;
            }
            var durations = [];
            for(var i = 0; i < audioElements.length; i++){
                durations.push(audioElements[i].duration);
            }
            setDurations(durations);
        }
    }

    const onSelectSong = (songIndex) => {
        props.setSongFromQueue(songIndex);
    }
    
    useEffect(() => {
        // Clear existing timer before starting a new one
        if(updateTimer) {
            clearInterval(updateTimer);
        }
        setUpdateTimer(setInterval(updateDurations, 50));
    }, [props.songQueue]);

    useEffect(() => {
        if(props.loading) {
            setDurations([]);
        }
    }, [props.loading]);

    return (
        <>
        {!props.showQueue ? <></> :
            <div className='position-fixed border border-rounded mediaqueue shadow'>
                <div className='row'>
                    <div className='col-12'>
                    </div>
                    <div className='col-12'>
                        <table className='table table-sm table-hover m-0'>
                            <thead>
                                <tr className='fw-bold text-dark' style={{ fontSize: '0.85rem' }}>
                                    <td className='border-top-0 pl-3'>
                                    </td>
                                    <td className='border-top-0 '>
                                        SONG
                                    </td>
                                    <td className='border-top-0 pr-3'>
                                        TIME
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                {props.loading ?
                                    <tr><td colSpan='3' className='text-center'> <Spinner /> </td></tr> :
                                    props.songQueue.map((s,i) => (
                                        <QueueItem key={i}
                                                   {...s}
                                                   duration={durations[i]}
                                                   onSelectSong={onSelectSong}
                                                   songIndex={i}
                                                   />
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        }
        </>
    )
}
