const BASE_URL = "https://mooserapidsmusic.com";
const BASE_API_URL = `${BASE_URL}/api`;

export const routes = {
    // API endpoints
    album:        `${BASE_API_URL}/album`,
    artist:       `${BASE_API_URL}/artist`,
    song:         `${BASE_API_URL}/song`,
    thread:       `${BASE_API_URL}/thread`,
    post:         `${BASE_API_URL}/post`,
    user:         `${BASE_API_URL}/user`,
    upload_album: `${BASE_API_URL}/upload_album`,
    // Routing endpoints
    static_base:  `${BASE_URL}/uploads`,
};
