import { Link } from 'react-router-dom';
//import Auth from './Auth';

var config = {
    //navbarBrandIcon: require("../images/crumb_icon.png"),
    navbarBrandIcon: null,
    navbarBrandText: "Moose Rapids Music",
    navbarLinks: [
        { path: "/albums", text: "Albums" },
        { path: "/artists", text: "Artists" },
    ],
    navbarExtras: [
        //<Auth key={0} isInline={true} />
    ],
};

export default function Header() {
    var navImg = <></>;
    if(config.navbarBrandIcon !== null){
        navImg = <img src={config.navbarBrandIcon} alt="" className="inline-block align-middle" height="26px"/>
    }

    var navLinks = <></>;
    if(config.navbarLinks !== null && config.navbarLinks.length > 0){
        navLinks = config.navbarLinks.map((link, idx) => (
            <li key={idx} className="nav-item">
                <Link to={link.path} className="nav-link">{link.text}</Link>
            </li>
        ));
    }

    return (
        <nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <Link to="/" className="navbar-brand">
                {navImg}
                <span className="mx-1 inline-block align-middle">{config.navbarBrandText}</span>
            </Link>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <div className="navbar-collapse">
                    <ul className="navbar-nav">
                        {navLinks}
                    </ul>
                </div>
            </div>
            {config.navbarExtras}
        </nav>
    )
}
