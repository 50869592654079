import { useState, useEffect } from 'react';

import axios from 'axios';
import { useParams } from 'react-router-dom';

import { routes } from '../api';

function ArtistTrack(props) {
    const onClick = (e) => {
        e.preventDefault();
        props.onPlay(props.song);
    }

    const getArtistName = () => {
        var artistName = "";
        props.song.artists.forEach((a,i) => {
            artistName = artistName + ((i>0) ? " & " : "") + a.name;
        })
        return artistName;
    }

    return (
        <tr onClick={onClick}>
            <td>{getArtistName()}</td>
            <td>{props.song.album.name}{props.song.disc === 2 ? ' (Tiebreakers)' : ''}</td>
            <td>{props.song.disc}</td>
            <td>{props.song.track}</td>
            <td>
                {props.song.winner ? <img className='me-2' src={require('../images/trophy.png')} alt='trophy' style={{width: 'auto', height: '25px'}} title='Winner!' /> : <></>}
                {props.song.name}
            </td>
        </tr>
    )
}

function Artist(props) {
    return (
        <>
            {(props.songs === null || props.artist === null) ? <></> :
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex">
                            <div className="flex-fill px-2">
                                <div className="row">
                                    <div className="col-12 fw-bold">
                                        {props.artist.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-4 table-responsive rounded">
                        <table className="table table-sm table-hover border shadow-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Artist</th>
                                    <th scope="col">Album</th>
                                    <th scope="col">Disc</th>
                                    <th scope="col">Track</th>
                                    <th scope="col">Title</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.songs.map((t,i) => (<ArtistTrack song={t} key={i} onPlay={props.onPlay}/>))}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </>
    );
}

export default function ArtistContainer(props) {
    const DEFAULT_ARTIST = {image: null, name: null, year: "", description: "", download_url: ""};
    const route_params = useParams();

    const [artist, setArtist] = useState(DEFAULT_ARTIST);
    const [songs, setSongs] = useState([]);
    const [errors, setErrors] = useState(false);

    const getArtistInfo = () => {
        axios.get(routes.artist, {
            params: {
                slug: route_params.artist,
            },
        })
        .then(res => {
            var dat = res.data.artists[0];
            setArtist({
                image: dat.photo,
                name: dat.name,
                year: dat.year,
            })
            axios.get(routes.song, {
                params: {
                    pk: dat.songs.map((s) => (s.pk)),
                }
            })
            .then(res => {
                let songMap = {};
                res.data.songs.forEach(s => {
                    if(!songMap[s.album.name]) {
                        songMap[s.album.name] = [];
                    }
                    songMap[s.album.name].push(s);
                });
                var orderedSongs = [];
                Object.keys(songMap).sort().forEach(k => {
                    var discmap = {}
                    songMap[k].forEach(song => {
                        if(!discmap[song.disc]) {
                            discmap[song.disc] = [];
                        }
                        discmap[song.disc].push(song)
                    });
                    Object.keys(discmap).sort().forEach(d => {
                        discmap[d].sort((a,b) => {
                            return a.track - b.track;
                        }).forEach(s => {
                            orderedSongs.push(s);
                        });
                    });
                });
                setSongs(orderedSongs);
                setErrors(false);
            })
            .catch(err => {
                console.log(err);
                setErrors(err.response.data.error_message);
            });
        })
        .catch(err => {
            console.log(err);
            setErrors(err.response.data.error_message);
        });
    }

    useEffect(() => {
        getArtistInfo();
    }, [props.artist]);

    const onPlay = (song) => {
        props.onPlay(artist, songs, song);
    }

    return (
        <div className="row">
            {!errors ? <></> :
                <div className="col-12 mb-4 text-center text-danger">
                    {errors}
                </div>
            }
            <div className="col-12">
                <Artist artist={artist} songs={songs} onPlay={onPlay} />
            </div>
        </div>
    )
}
