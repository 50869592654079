import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { routes } from '../api';


function AlbumListItem(props) {
    const getDescription = () => {
        var ret = <></>;
        if(props.album && props.album.description) {
            let re = /(\d+)(\w+)(.*)/;
            let tmp = re.exec(props.album.description);
            try {
                ret = <>{tmp[1]}<sup>{tmp[2]}</sup>{tmp[3]}</>;
            } catch(e) {
                ret = <>{props.album.description}</>;
            }
        }
        return ret;
    }

    const img = (
        (props.album.artwork_url) ?
            <img src={`${routes.static_base}/${props.album.artwork_url}`}
                 className="card-img-top"
                 alt="..."
                 />
        :
            <></>
    );

    return (
        <div className="card mx-2 my-4 col-12 col-md-5 col-lg-3" style={{width: "18rem"}}>
            <Link to={"/albums/" + props.album.slug} className="text-reset text-decoration-none">
                <div className="card-body">
                    {img}
                    <h5 className="card-title">{props.album.name}</h5>
                    <h6 style={{ fontSize: "0.85rem" }}
                        className="card-subtitle fw-normal text-muted"
                        >
                        {getDescription()}
                        {props.album.description ? <br className="mb-1"/> : ""}
                        {props.album.year}
                    </h6>
                </div>
            </Link>
        </div>
    )
}

function AlbumList(props) {
    return (
        <>
            {props.albums === null ? <></> :
                <div className="d-flex justify-content-center align-content-stretch flex-wrap">
                    {props.albums.map((a, i) => (<AlbumListItem key={i} album={a} />))}
                </div>
            }
        </>
    )
}

export default function AlbumListContainer(props) {
    const [albums, setAlbums] = useState(null);
    const [errors, setErrors] = useState(false);

    const getAlbums = () => {
        axios.get(routes.album)
        .then((res) => {
            setAlbums(res.data.albums);
            setErrors(false);
        })
        .catch((err) => {
            console.log(err);
            setErrors(err.response.data.error_message);
        });
    }

    useEffect(() => {
        getAlbums();
    }, [])

    return (
        <div className="row">
            {!errors ? <></> :
                <div className="col-12 mb-4 text-center text-danger">
                    {errors}
                </div>
            }
            <div className="col-12">
                <AlbumList albums={albums} />
            </div>
        </div>
    )
}
