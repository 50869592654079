import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import { routes } from '../api';

function Track(props) {
    const [track_name, setTrackName] = useState('');
    const [artist_name, setArtistName] = useState('');
    const [track_file, setTrackFile] = useState(null);
    const [track_collapse, setTrackCollapse] = useState(true);
    const [errors, setErrors] = useState({
        track_name: false,
        artist_name: false,
    });

    useEffect(() => {
        props.updateTracks(props.trackNum, {
            name: track_name,
            artist: artist_name,
            song_file: track_file,
            id: props.trackId
        });
    }, [track_name, artist_name, track_file, props.trackId]);

    const validateTrackName = () => {
        if(track_name.length === 0) {
            setErrors({
                ...errors,
                track_name: 'Track name must not be empty'
            });
        } else {
            setErrors({
                ...errors,
                track_name: false
            });
        }
    }

    const validateArtistName = () => {
        if(artist_name.length === 0) {
            setErrors({
                ...errors,
                artist_name: 'Track name must not be empty'
            });
        } else {
            setErrors({
                ...errors,
                artist_name: false
            });
        }
    }

    return (
        <div className='row border rounded shadow-sm pt-3 mt-3'>
            <div className='col-12 mb-3 fs-5 fw-bold'>
                <div className='d-flex align-items-center'>
                    <div className='me-3'>
                        Track {props.trackNum}
                    </div>
                    <div className='flex-fill'>
                        <div className='fw-normal'>
                            {track_name}
                        </div>
                        <div className='fw-normal text-muted'
                             style={{ fontSize: '0.80rem' }}
                             >
                            {artist_name === '' ? '' : ' by ' + artist_name}
                        </div>
                    </div>
                    <button type='button'
                            className='btn-sm btn-close me-3'
                            aria-label='Close'
                            title='Delete Track'
                            onClick={(e) => {e.preventDefault(); props.deleteTrack(props.trackNum);}}
                            />
                    <div>
                        <button className={'btn btn-sm font-monospace ' + (track_collapse ? 'btn-outline-danger' : 'btn-outline-primary') }
                                title={track_collapse ? 'collapse' : 'expand'}
                                onClick={(e) => {e.preventDefault(); setTrackCollapse(!track_collapse)}}
                                >
                            [{track_collapse ? '-' : '+'}]
                        </button>
                    </div>
                </div>
            </div>
            {!track_collapse ? <></> :
                <>
                    <div className='col-12 col-lg-4 mb-3 text-center'>
                        <div className='card h-100'>
                            <div className='card-body'>
                                {track_file ? track_file.name : 'Upload Track File'}
                            </div>
                            <div id='' className='card-footer'>
                                <div className='btn btn-primary'
                                     onClick={(e) => {document.getElementById('disc'+props.discNum+'-track'+props.trackId+'-upload').click()}}
                                     >
                                    Upload Song
                                    <input type="file"
                                           accept='audio/*'
                                           id={'disc'+props.discNum+'-track'+props.trackId+'-upload'}
                                           className="d-none invisible"
                                           aria-hidden="true"
                                           tabIndex="-1"
                                           onChange={(e) => {setTrackFile(e.target.files[0])}}
                                           />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-8' id={'disc'+props.discNum+'-track'+props.trackId+'-form-fields'}>
                        <div className='mb-3 form-floating'>
                            <input className={"form-control " + (errors.track_name ? 'is-invalid' : '')}
                                   type="text"
                                   id={"track-name-disc"+props.discNum+'-track'+props.trackId}
                                   name={"track-name-disc"+props.discNum+'-track'+props.trackId}
                                   placeholder='..track name..'
                                   value={track_name}
                                   onChange={(e) => {setTrackName(e.target.value)}}
                                   onBlur={(e) => {validateTrackName()}}
                                   />
                            <label htmlFor={"track-name-disc"+props.discNum+'-track'+props.trackId}>Track Name</label>
                            {errors.track_name ?
                                <div className='form-text text-danger'>{errors.track_name}</div>
                                : <></>
                            }
                        </div>
                        <div className='mb-3 form-floating'>
                            <input className={"form-control " + (errors.artist_name ? 'is-invalid' : '')}
                                   type="text"
                                   id={"artist-name-disc"+props.discNum+"-track"+props.trackId}
                                   name={"artist-name-disc"+props.discNum+'-track'+props.trackId}
                                   placeholder='..artist name..'
                                   value={artist_name}
                                   onChange={(e) => {setArtistName(e.target.value)}}
                                   onBlur={(e) => {validateArtistName()}}
                                   />
                            <label htmlFor={"artist-name-disc"+props.discNum+'-track'+props.trackId}>Artist Name</label>
                            {errors.artist_name ?
                                <div className='form-text text-danger'>{errors.artist_name}</div>
                                : <></>
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    )
}


function Disc(props) {
    const [tracks, setTracks] = useState([]);
    const [trackCnt, setTrackCnt] = useState(0);
    const [collapsed, setCollapsed] = useState(true);

    const updateTracks = (trackNum, trackData) => {
        props.updateDiscs(props.discNum, trackNum, trackData);
    }

    const addNewTrack = (e) => {
        e.preventDefault();
        setTracks([
            ...tracks,
            {
                trackNum: tracks.length + 1,
                discNum: props.discNum,
                id: trackCnt + 1
            }
        ])
        setTrackCnt(trackCnt + 1);
    }

    const deleteTrack = (track_num) => {
        let tmpTracks = [];
        let trackCnt = 1;
        tracks.forEach((t) => {
            if(t.trackNum !== track_num) {
                tmpTracks.push({
                        ...t,
                        trackNum: trackCnt
                });
                trackCnt = trackCnt + 1;
            } else {
                props.deleteFromDisc(props.discNum, t.id);
            }
        });
        setTracks(tmpTracks);
    }

    return (
        <div className='row justify-content-md-center border rounded shadow-sm my-3 py-3'>
            <div className='col-12 mb-3 fs-5 fw-bold'>
                <div className='d-flex align-items-center'>
                    <div className='flex-fill'>
                        {props.name}
                        {!collapsed ?  <></> :
                            <span className='ms-3 text-muted fw-normal'>{tracks.length} tracks</span>
                        }
                    </div>
                    <div>
                        <button className={'btn btn-sm font-monospace ' + (collapsed ? 'btn-outline-primary' : 'btn-outline-danger') }
                                title={collapsed ? 'expand' : 'collapse'}
                                onClick={(e) => {e.preventDefault(); setCollapsed(!collapsed)}}
                                >
                            [{collapsed ? '+' : '-'}]
                        </button>
                    </div>
                </div>
            </div>
            {collapsed ?
                <div className='col-12 text-end text-muted'>Expand to edit tracks</div>
                : 
                <></>
            }
            <div className={'col-10 ' + (collapsed ? 'd-none' : '')}>
                {tracks.map(t => (<Track key={t.id} trackId={t.id} trackNum={t.trackNum} discNum={t.discNum} updateTracks={updateTracks} deleteTrack={deleteTrack} />))}
            </div>
            <div className={'col-10 mt-3 text-end ' + (collapsed ? 'd-none' : '')}>
                <button type='submit'
                        className='btn btn-outline-primary'
                        title='Add New Track'
                        onClick={addNewTrack}
                        >
                    Add Track
                </button>
            </div>
        </div>
    );
}


function AlbumForm(props) {
    const [album_name, setAlbumName] = useState('');
    const [album_year, setAlbumYear] = useState('');
    const [album_description, setAlbumDescription] = useState('');
    const [album_artwork, setAlbumArtwork] = useState(null);
    const [album_ff_height, setAlbumFfHeight] = useState('');
    const [errors, setErrors] = useState({
        album_name: false,
        album_year: false,
        album_description: false,
    });

    useEffect(() => {
        props.updateAlbum('name', album_name);
    }, [album_name]);

    useEffect(() => {
        props.updateAlbum('year', album_year);
    }, [album_year]);

    useEffect(() => {
        props.updateAlbum('description', album_description);
    }, [album_description]);

    useEffect(() => {
        props.updateAlbum('artwork', album_artwork);
    }, [album_artwork]);

    useEffect(() => {
        let a = document.getElementById('album-form-fields');
        if(a) {
            let h = 0;
            for(let i = 0; i < a.children.length; i++) {
                h = h + a.children[i].clientHeight;
            }
            let mh = (a.clientHeight - h) / 3;
            setAlbumFfHeight(h + mh + mh);
        }
    }, [errors]);

    const validateAlbumName = () => {
        if(album_name.length === 0) {
            setErrors({
                ...errors,
                album_name: 'Album name must not be empty'
            });
        } else {
            setErrors({
                ...errors,
                album_name: false
            });
        }
    }

    const validateAlbumYear = () => {
        if(album_year.length === 0) {
            setErrors({
                ...errors,
                album_year: 'Album year must not be empty'
            });
        } else {
            if(isNaN(album_year)) {
                setErrors({
                    ...errors,
                    album_year: 'Album year must be a number'
                });
            } else {
                setErrors({
                    ...errors,
                    album_year: false
                });
            }
        }
    }

    const validateAlbumDescription = () => {
        if(album_description.length > 1000) {
            setErrors({
                ...errors,
                album_description: 'Album description must be less than 1,000 characters'
            });
        } else {
            setErrors({
                ...errors,
                album_description: false
            });
        }
    }

    const getCardFooterHeight = () => {
    }

    const getMaxImageHeight = () => {
        let a = document.getElementById('album-artwork-card-footer');
        if(!a) {
            return 'auto';
        }
        let cfh = a.clientHeight;
        let tmp = album_ff_height - cfh;
        return tmp + 'px';
    }

    return (
        <>
            <div className='row border rounded shadow-sm pt-3'>
                <div className='col-12 mb-3 fs-5 fw-bold'>
                    Album Details
                </div>
                <div className='col-12 col-lg-4 mb-3 text-center'
                     style={{ height: album_ff_height+'px', maxHeight: album_ff_height+'px' }}
                     >
                    <div className='card h-100'>
                        { album_artwork ?
                            <img src={URL.createObjectURL(album_artwork)}
                                 className='card-img-top img-thumbnail rounded mx-auto d-block'
                                 style={{ maxHeight: getMaxImageHeight(), width: 'auto'}}
                                 alt='No Image Uploaded'
                                 />
                            : 
                            <div className='card-body'>Upload new image</div>
                        }
                        <div id='album-artwork-card-footer' className='card-footer'>
                            <div className='btn btn-primary'
                                 onClick={(e) => {document.getElementById('albumArtworkUpload').click()}}
                                 >
                                Upload Artwork
                                <input type="file"
                                       id='albumArtworkUpload'
                                       accept="image/*"
                                       className="d-none invisible"
                                       aria-hidden="true"
                                       tabIndex="-1"
                                       onChange={(e) => {setAlbumArtwork(e.target.files[0])}}
                                       />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-8' id='album-form-fields'>
                    <div className='mb-3 form-floating'>
                        <input className={"form-control " + (errors.album_name ? 'is-invalid' : '')}
                               type="text"
                               id="album_name"
                               name="album_name"
                               placeholder='..album name..'
                               value={album_name}
                               onChange={(e) => {setAlbumName(e.target.value)}}
                               onBlur={(e) => {validateAlbumName()}}
                               />
                        <label htmlFor="album_name">Album Name</label>
                        {errors.album_name ?
                            <div className='form-text text-danger'>{errors.album_name}</div>
                            : <></>
                        }
                    </div>
                    <div className='mb-3 form-floating'>
                        <input className={"form-control " + (errors.album_year ? 'is-invalid' : '')}
                               type="text"
                               id="album_year"
                               name="album_year"
                               placeholder='..album year..'
                               value={album_year}
                               onChange={(e) => {setAlbumYear(e.target.value)}}
                               onBlur={(e) => {validateAlbumYear()}}
                               />
                        <label htmlFor="album_year">Album Year</label>
                        {errors.album_name ?
                            <div className='form-text text-danger'>{errors.album_year}</div>
                            : <></>
                        }
                    </div>
                    <div className='mb-3 form-floating'>
                        <input className={"form-control " + (errors.album_description ? 'is-invalid' : '')}
                               type="text"
                               id="album_description"
                               name="album_description"
                               placeholder='..album description..'
                               value={album_description}
                               onChange={(e) => {setAlbumDescription(e.target.value)}}
                               onBlur={(e) => {validateAlbumDescription()}}
                               />
                        <label htmlFor="album_description">Album Description</label>
                        {errors.album_name ?
                            <div className='form-text text-danger'>{errors.album_description}</div>
                            : <></>
                        }
                    </div>
                </div>
            </div>
            <div className='col-12'>
                <Disc key={1} discNum={1} updateDiscs={props.updateDiscs} deleteFromDisc={props.deleteFromDisc} name="Main Songs" />
                <Disc key={2} discNum={2} updateDiscs={props.updateDiscs} deleteFromDisc={props.deleteFromDisc} name="Tiebreakers" />
            </div>
        </>
    )
}

export default function UploadAlbumContainer(props) {
    const [album, setAlbum] = useState({
        artwork: null,
        name: null,
        year: null,
        description: null,
    });
    const [discs, setDiscs] = useState([
        {songs: []},
        {songs: []}
    ])
    const [confirmUpload, setConfirmUpload] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const navigate = useNavigate();

    const uploadAlbum = () => {
        if(!confirmUpload) {
            setConfirmUpload(true);
            return;
        }
        setUploadError(false);
        var errors = [];
        if(!album.name || album.name === ''){
            errors.push('Album name required');
        }
        if(!album.year || album.year === '' || isNaN(album.year)) {
            errors.push('Album year required and must be a valid number');
        }
        if(!album.artwork || album.artwork === '') {
            errors.push('Album artwork required');
        }
        discs.forEach((d, di) => {
            d.songs.forEach((s, si) => {
                if(!s.name || s.name === '') {
                    errors.push(`Song name required for Disc ${di+1} Track ${si+1}`);
                }
                if(!s.artist || s.artist === '') {
                    errors.push(`Artist required for Disc ${di+1} Track ${si+1}`);
                }
                if(!s.song_file) {
                    errors.push(`Audio file required for Disc ${di+1} Track ${si+1}`);
                }
            });
        });
        if(errors.length > 0) {
            setUploadError(errors.join('\n'));
            setConfirmUpload(false);
            return;
        }
        // No errors detected in the forms, moving on.
        const album_data = {
            artwork: album.artwork.name,
            name: album.name,
            year: album.year,
            description: album.description,
        }
        const disc_data = [
            {songs: discs[0].songs.map(s => ({
                name: s.name,
                artist: s.artist,
                file: s.song_file.name
            }))},
            {songs: discs[1].songs.map(s => ({
                name: s.name,
                artist: s.artist,
                file: s.song_file.name
            }))},
        ]
        const formData = new FormData();
        formData.append('artwork', album.artwork);
        discs.forEach((d) => {
            d.songs.forEach((s) => {
                formData.append('track-'+s.name, s.song_file);
            });
        });
        formData.append('songs', discs[0].songs[0].song_file);
        formData.append('album', JSON.stringify(album_data));
        formData.append('discs', JSON.stringify(disc_data));
        axios.post(routes.upload_album, formData)
        .then(res => {
            navigate('/albums/' + res.data.album.slug)
        })
        .catch(err => {
            console.log(err);
            if(err.response.status === 413) {
                setUploadError(`Request is too large`);
            } else {
                setUploadError(err.response.data.error_message);
            }
        });
        setConfirmUpload(false);
    }

    const updateAlbum = (key, value) => {
        let tmp = {...album};
        tmp[key] = value;
        setAlbum(tmp);
    }

    const updateDiscs = (disc, songNum, songData) => {
        let tmp = [...discs];
        tmp[disc-1].songs[songNum-1] = songData;
        setDiscs(tmp);
    }

    const deleteFromDisc = (discNum, trackId) => {
        let tmp = [];
        discs[discNum-1].songs.forEach(s => {
            if(s.id !== trackId) {
                tmp.push(s);
            }
        });
        let tmpdiscs = [...discs];
        tmpdiscs[discNum-1].songs = tmp;
        setDiscs(tmpdiscs);
    }

    return (
        <div className='row'>
            <div className='fs-1 mb-4 col-12 text-center fw-bold'>
                Upload New Album
            </div>
            <div className='col-12 mb-4 text-end'>
                {!confirmUpload ?
                    <button className='btn btn-outline-danger' onClick={uploadAlbum}>Upload</button> :
                    <>
                        <button className='btn btn-outline-danger mx-2' onClick={uploadAlbum}>Confirm Upload</button>
                        <button className='btn btn-outline-primary' onClick={(e) => {e.preventDefault(); setConfirmUpload(false)}}>Cancel</button>
                    </>
                }
            </div>
            {!uploadError ? <></> :
                <div className='col-12 mb-4 text-center text-danger' style={{ whiteSpace: 'pre-line' }}>
                    {uploadError}
                </div>
            }
            <div className='col-12'>
                <AlbumForm updateAlbum={updateAlbum}
                           updateDiscs={updateDiscs}
                           deleteFromDisc={deleteFromDisc}
                           />
            </div>
        </div>
    )
}
