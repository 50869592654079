import { useState, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AlbumContainer from './components/Album';
import AlbumListContainer from './components/AlbumList';
import ArtistContainer from './components/Artist';
import ArtistListContainer from './components/ArtistList';
import Header from './components/Header';
import Home from './components/Home';
import MediaController from './components/MediaController';
import SongQueue from './components/SongQueue';
import UploadAlbumContainer from './components/UploadAlbum';

function App() {
    const [albumInfo, setAlbumInfo] = useState(null);
    const [songQueue, setSongQueue] = useState(null);
    const [currentSong, setCurrentSong] = useState(0);
    const [setToPlay, setSetToPlay] = useState(false);
    const [setToPause, setSetToPause] = useState(false);
    const [togglePlayPause, setTogglePlayPause] = useState(false);
    const [clearingCurrentQueue, setClearingCurrentQueue] = useState(null);
    const [nextQueue, setNextQueue] = useState(null);
    const [showQueue, setShowQueue] = useState(null);
    const [loading, setLoading] = useState(null);
    const [seeking, setSeeking] = useState(false);

    const refCurrentSong = useRef();

    const beginScanSong = (e) => {
        var t = document.getElementById('progress-slider');
        var o = document.getElementById('current-song');
        o.pause();
        var n = (e.clientX - t.offsetLeft) / (t.offsetWidth - 30);
        o.currentTime = n * o.duration;
        setSetToPause(true);
        setSeeking(true);
    }

    const scanSong = (e) => {
        e.preventDefault();
        // Scan Song
        if(seeking) {
            var t = document.getElementById('progress-slider');
            var o = document.getElementById('current-song');
            var n = (e.clientX - t.offsetLeft) / (t.offsetWidth - 30);
            o.currentTime = n * o.duration;
        }
    }

    const onMouseLeave = (e) => {
        e.preventDefault();
        if(seeking) {
            endScanSong(e);
        }
    }

    const endScanSong = (e) => {
        e.preventDefault();
        if(seeking) {
            var a = document.getElementById('current-song');
            a.play();
            setSeeking(false);
            setSetToPlay(true);
        }
    }

    const onSongChange = (change) => {
        var songIdx = refCurrentSong.current + change;
        if(songIdx < 0){
            songIdx = 0;
        } else if(songQueue && songIdx > songQueue.length) {
            songIdx = songQueue.length;
        }
        setCurrentSong(songIdx);
        setSetToPlay(true);
    }

    const onNewQueue = (album, songs, song) => {
        if(albumInfo !== undefined && albumInfo !== null && albumInfo.name === album.name){
            let currSong = 0;
            for(let i = 0; i < songs.length; i++) {
                if(song.pk === songs[i].pk) {
                    currSong = i;
                    i = songs.length;
                }
            }
            setCurrentSong(currSong);
        } else {
            let currSong = 0;
            for(let i = 0; i < songs.length; i++){
                if(song.pk === songs[i].pk){
                    currSong = i;
                    i = songs.length;
                }
            }
            setClearingCurrentQueue(true);
            setCurrentSong('loading');
            let nextSongs = [];
            songs.forEach((s, i) => {
                if(s.streamable && s.stream_url) {
                    nextSongs.push(s);
                } else {
                    if(i < currSong) {
                        currSong = currSong - 1;
                    }
                }
            });
            setNextQueue({
                songs: nextSongs,
                album: album,
                track: currSong
            });
            setLoading(true);
        }
    }

    const clearSetToPlay = () => {
        setSetToPlay(false);
    }

    const clearSetToPause = () => {
        setSetToPause(false);
    };

    const clearTogglePlayPause = () => {
        setTogglePlayPause(false);
    }

    const toggleQueue = (e) => {
        e.preventDefault();
        setShowQueue(!showQueue);
    }

    const setSongFromQueue = (songIndex) => {
        if(songIndex < 0){
            songIndex = 0;
        } else if(songIndex > songQueue.length) {
            songIndex = songQueue.length;
        }
        setCurrentSong(songIndex);
        setSetToPlay(true);
    }

    const _handleKeyPress = (e) => {
    //    if(e.key === ' ' || e.key === 'Spacebar') {
    //        e.preventDefault();
    //        setTogglePlayPause(true);
    //    }
    }

    useEffect(() => {
        refCurrentSong.current = currentSong;
    }, [currentSong]);

    useEffect(() => {
        if(clearingCurrentQueue && nextQueue) {
            if(nextQueue.songs.length > 0) {
                setSongQueue(nextQueue.songs);
                setAlbumInfo(nextQueue.album);
                setCurrentSong(nextQueue.track);
                setClearingCurrentQueue(false);
                setNextQueue(null);
                setSetToPlay(true);
                setLoading(false);
            } else {
                setClearingCurrentQueue(false);
                setSongQueue([]);
                setAlbumInfo(null);
            }
        }
    }, [clearingCurrentQueue, nextQueue]);

    return (
        <BrowserRouter>
            <div className="App"
                 style={{ minHeight: '100vh' }}
                 tabIndex="0"
                 onMouseUp={endScanSong}
                 onMouseMove={scanSong}
                 onMouseLeave={onMouseLeave}
                 onKeyDown={(e) => _handleKeyPress(e)}
                 >
                        <Header />
                        <div style={{height:"25px"}} />
                        <div className="container" style={{paddingBottom: "125px"}}>
                            <Routes>
                                <Route exact path='/' element={<Home  onPlay={onNewQueue} />} />
                                <Route exact path='/albums' element={<AlbumListContainer />} />
                                <Route path='/albums/:album' element={<AlbumContainer onPlay={onNewQueue} />} />
                                <Route exact path='/artists' element={<ArtistListContainer />} />} />
                                <Route path='/artists/:artist' element={<ArtistContainer onPlay={onNewQueue} />} />
                                <Route exact path='/upload' element={<UploadAlbumContainer />} />
                            </Routes>
                        </div>
                        <MediaController songQueue={songQueue}
                                         currentSong={currentSong}
                                         albumInfo={albumInfo}
                                         setToPlay={setToPlay}
                                         clearSetToPlay={clearSetToPlay}
                                         togglePlayPause={togglePlayPause}
                                         clearTogglePlayPause={clearTogglePlayPause}
                                         setToPause={setToPause}
                                         clearSetToPause={clearSetToPause}
                                         nextSong={onSongChange}
                                         beginScanSong={beginScanSong}
                                         toggleQueue={toggleQueue}
                                         setLoadingSongs={setLoading}
                                         />
                        <SongQueue songQueue={songQueue}
                                   showQueue={showQueue}
                                   loading={loading}
                                   setSongFromQueue={setSongFromQueue}
                                   />
            </div>
        </BrowserRouter>
    );
}


                                //<Route exact path='/' render={(props) => <Home {...props} onPlay={onNewQueue}/>} />
                                //<Route exact path='/albums' render={(props) => <AlbumListContainer {...props} onPlay={onNewQueue}/>} />
                                //<Route path='/albums/:album' render={(props) => <AlbumContainer {...props} onPlay={onNewQueue} />} />
                                //<Route exact path='/artists' render={(props) => <ArtistListContainer {...props} onPlay={onNewQueue} />} />
                                //<Route path='/artists/:artist' render={(props) => <ArtistContainer {...props} onPlay={onNewQueue} />} />

export default App;
