import React from 'react';

export default function Spinner() {
    return (
        <div className="row">
            <div className="col-12">
                <div className="spinner-border text-dark" role="status">
                    <span className="sr-only"></span>
                </div>
            </div>
        </div>
    )
}
