import { useState, useEffect } from 'react';

import axios from 'axios';
import { useParams } from 'react-router-dom';

import { routes } from '../api';


function AlbumTrack(props) {
    const [hovering, setHovering] = useState(false);

    const onClick = (e) => {
        e.preventDefault();
        props.onPlay(props.song);
    }

    const getArtistName = () => {
        let name;
        if(props.song.anonymous) {
            name = 'Anonymous';
        } else {
            name = '';
            props.song.artists.forEach((a, i) => {
                name = name + ((i > 0) ? " & " : "") + a.name;
            });
        }
        return name;
    }

    const track = (hovering ? 
        <img src={require('../images/play.svg').default} alt='...' style={{ width: '10px', height: '12px'}} />
        :
        props.song.track
    );

    return (
        <tr onClick={onClick}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            >
            <td>{track}</td>
            <td>{getArtistName()}</td>
            <td>
                {props.song.winner ? <img className='me-2' src={require('../images/trophy.png')} alt='trophy' style={{width: 'auto', height: '25px'}} title='Winner!' /> : <></>}
                {props.song.name}
            </td>
        </tr>
    )
}

function Album(props) {
    return (
        <>
            {(props.songs === null || props.album === null) ? <></> :
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex align-items-center">
                            <div className="mr-2">
                                <img src={props.album.artwork_url ? `${routes.static_base}/${props.album.artwork_url}` : ''}
                                     className="border"
                                     alt="..."
                                     style={{height: '100px', width: '100px'}}
                                     />
                            </div>
                            <div className="flex-fill px-2">
                                <div className="row">
                                    <div className="col-12 fw-bold">
                                        {props.album.name}
                                    </div>
                                    <div className="col-12 text-muted">
                                        {props.album.year} - {props.album.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-4 table-responsive rounded">
                        <table className="table table-sm table-hover border shadow-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Track</th>
                                    <th scope="col">Artist</th>
                                    <th scope="col">Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.songs.map((t,i) => (<AlbumTrack song={t} key={t.pk} onPlay={props.onPlay}/>))}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12 text-end">
                        <form method="get" action={`${routes.static_base}/${props.album.download_url}`}>
                            <button className="btn btn-sm btn-success" type="submit">Download</button>
                        </form>
                    </div>
                </div>
            }
        </>
    )
}

export default function AlbumContainer(props) {
    const DEFAULT_ALBUM = {artwork_url: null, name: null, year: null, description: null};

    const [image, setImage] = useState("");
    const [album, setAlbum] = useState(DEFAULT_ALBUM);
    const [songs, setSongs] = useState(null);
    const [errors, setErrors] = useState(false);

    const route_params = useParams();

    const getAlbumInfo = () => {
        axios.get(routes.album, {
            params: {
                slug: route_params.album,
            }
        })
        .then(res => {
            setErrors(false);
            var album_data = res.data.albums[0];
            setAlbum({
                artwork_url: album_data.artwork_url,
                name: album_data.name,
                year: album_data.year,
                description: album_data.description,
                download_url: album_data.download_url,
            });
            axios.get(routes.song, {
                params: {
                    pk: album_data.songs.map((s) => (s.pk)),
                }
            })
            .then(res => {
                setSongs(res.data.songs);
                setErrors(false);
            })
            .catch(err => {
                console.log(err);
                setErrors(err.response.data.error_message);
            });
        })
        .catch(err => {
            console.log(err);
            setErrors(err.response.data.error_message);
        });
    }

    useEffect(() => {
        getAlbumInfo();
    }, [props.album])

    const onPlay = (song) => {
        props.onPlay(album, songs, song);
    }

    return (
        <div className="row">
            {!errors ? <></> :
                <div className="col-12 mb-4 text-center text-danger">
                    {errors}
                </div>
            }
            <div className="col-12">
                <Album album={album} songs={songs} onPlay={onPlay} />
            </div>
        </div>
    )
}
