export function song_to_resource(song_stream_url) {
    var res = song_stream_url.replace(/-/g,"_");
    res = res.substring(3, res.length-4);
    res = res.replace(/\./g,"_");
    res = res.replace(/!/g,"_");
    res = res.replace(/\?/g,"_");
    res = res.replace(/,/g,"_");
    if(!isNaN(res.charAt(0))) {
        res = 'a_' + res;
    }
    return res;
}

export function timeToMMSS(total_seconds) {
    if(isNaN(total_seconds)) {
        return '';
    }
    var minutes = Math.floor(total_seconds / 60);
    var seconds = Math.floor(total_seconds - (minutes * 60));
    return minutes.toString() + ":" + zfill(seconds.toString(), 2, 0);
};

export function zfill(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}
