import { Link } from 'react-router-dom';

import axios from 'axios';

import { routes } from '../api';

export default function Home(props) {
    const onClick = (e) => {
        e.preventDefault();
        axios.get(routes.song, {
            params: {
                shuffle: true,
            }
        })
        .then(res => {
            props.onPlay({name: "shuffle"}, res.data.songs, 0);
        })
        .catch(e => {
            console.log(e);
        });
    }

    return (
        <div className="row">
            <div className="col-12 text-center">
                <h3>Moose Rapids Music</h3>
            </div>
            <div className="col-12 text-center">
                <div>
                    This is the place to listen to all your favorite Moose Rapids tunes! And also to download them!
                </div>
            </div>
            <div className="col-12 text-center">
                <div>
                    Check out the available albums <Link to="/albums">HERE</Link>
                </div>
                <div>
                    Or the available artists <Link to="/artists">HERE</Link>
                </div>
            </div>
            <div className="col-12 text-center my-4">
                <div>
                    Or just play the music!
                </div>
                <div>
                    <button className="btn btn-small btn-success" onClick={onClick}>
                        Shuffle All
                    </button>
                </div>
            </div>
        </div>
    );
}
