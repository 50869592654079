import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { images } from '../resources';
import { routes } from '../api';

function ArtistListItem(props) {
    const img = (props.artist.photo ? 
            <img src={`${routes.static_base}/${props.artist.photo}`}
                 className="card-img-top"
                 alt="."
                 />
        :
            <img src={images.artist_default}
                 style={{ height: "100px", width: "100px" }}
                 className="card-img-top mb-2"
                 alt="."
                 />
    );

    return (
        <div className="card mx-2 my-4 col-12 col-md-5 col-lg-3" style={{width: "18rem"}}>
            <Link to={"/artists/" + props.artist.slug} className="text-reset text-decoration-none">
                <div className="card-body text-center">
                    {img}
                    <h5 className="card-title">{props.artist.name}</h5>
                </div>
            </Link>
        </div>
    )
}

function ArtistList(props) {
    return (
        <>
            {props.artists === null ? <></> :
                <div className="d-flex justify-content-center align-content-stretch flex-wrap">
                    {props.artists.map((a,i) => (<ArtistListItem key={i} artist={a} />))}
                </div>
            }
        </>
    )
}

export default function ArtistListContainer(props) {
    const [artists, setArtists] = useState([]);
    const [errors, setErrors] = useState(false);

    const getArtists = () => {
        axios.get(routes.artist)
        .then((res) => {
            setArtists(res.data.artists);
            setErrors(false);
        })
        .catch((err) => {
            console.log(err);
            setErrors(err.response.data.error_message);
        });
    }

    useEffect(() => {
        getArtists();
    }, []);

    return (
        <div className="row">
            {!errors ? <></> :
                <div className="col-12 mb-4 text-center text-danger">
                    {errors}
                </div>
            }
            <div className="col-12">
                <ArtistList artists={artists} />
            </div>
        </div>
    );
}
