import { useState, useEffect, useRef } from 'react';

import { routes } from '../api';
import { timeToMMSS } from '../utils';

const icons = {
    play: require("../images/play.png"),
    pause: require("../images/pause2.svg").default,
    next: require("../images/next.png"),
    prev: require("../images/prev.png"),
};


const sliderStyle = {
    width: "100%",
    height: "15px",
    borderRadius: "5px",
    background: "#d3d3d3",
    outline: "none",
}

function VolumeSlider(props) {
    const [volume, setVolume] = useState(100);
    const [muted, setMuted] = useState(false);

    const refVolume = useRef();

    const updateVolume = (volume) => {
        var audios = document.getElementsByTagName("audio");
        if(audios !== undefined && audios !== null){
            for(var i = 0; i < audios.length; i++){
                audios[i].volume = volume/100;
            }
        }
    }

    useEffect(() => {
        if(volume !== 0) {
            setMuted(false);
            refVolume.current = volume;
        }
        updateVolume(volume)
    }, [volume]);

    useEffect(() => {
        muted ? setVolume(0) : setVolume(refVolume.current);
    }, [muted]);

    const toggleMute = (e) => {
        e.preventDefault();
        setMuted(!muted);
    };

    return (<></>);
    //return (
    //    <>
    //        <div onClick={toggleMute}>
    //            <img style={{ height: "25px"}} src={require("../images/mute.svg").default} alt="volume" />
    //        </div>
    //        <div className="mx-2">
    //            <input type="range"
    //                   min="1" max="100" value={volume}
    //                   className="slider"
    //                   style={sliderStyle}
    //                   name="volume"
    //                   id="myRange"
    //                   onChange={(e) => {setVolume(e.target.value)}}
    //                   />
    //        </div>
    //    </>
    //)
}


function AudioItem(props) {
    const [csId, setCsid] = useState('');

    useEffect(() => {
        setCsid(props.isCurrentSong ? "current-song" : "");
    }, [props]);

    return (
        <>
            {(props.source === null || !props.streamable) ?
                <audio src='' type="audio/mpeg" id={csId} /> :
                <audio src={props.source} type="audio/mpeg" id={csId} />
            }
        </>
    )
}

export default function MediaController(props) {
    const [playpause, setPlayPause] = useState('play');
    const [playButton, setPlayButton] = useState(icons.play);
    const [prevAction, setPrevAction] = useState('restart');
    const [prevSongTimer, setPrevSongTimer] = useState(null);
    const [progressPct, setProgressPct] = useState(0);
    const [updateTimer, setUpdateTimer] = useState(null);
    const [currArtist, setCurrArtist] = useState('');
    const [currAlbum, setCurrAlbum] = useState('');
    const [currSongName, setCurrSongName] = useState('');
    const [currProgress, setCurrProgress] = useState(0);
    const [currDuration, setCurrDuration] = useState(0);
    const [currTime, setCurrTime] = useState(0);

    const updateSongInfo = () => {
        if(props.songQueue && props.songQueue.length > 0) {
            var curSong = props.songQueue[props.currentSong];
            var artistName;
            if(curSong.anonymous) {
                artistName = 'Anonymous';
            } else {
                artistName = '';
                curSong.artists.forEach((a,i) => {
                    artistName = artistName + ((i > 0) ? " & " : "") + a.name;
                });
            }
            setCurrArtist(artistName);
            setCurrSongName(curSong.name);
            setCurrAlbum(curSong.album.name);
        } else {
            setCurrArtist('');
            setCurrSongName('');
            setCurrAlbum('');
            setCurrProgress(0);
            setCurrDuration(0);
            setCurrTime(0);
        }
    }

    const updateProgress = () => {
        var currentSong = document.getElementById("current-song");
        var pct = 0;
        if(currentSong !== undefined && currentSong !== null){
            pct = 100 * (currentSong.currentTime / currentSong.duration);
            setCurrProgress(pct);
            setCurrDuration(currentSong.duration);
            setCurrTime(currentSong.currentTime);
            if(currentSong.currentTime <= 1){
                setPrevAction('previous');
            } else {
                if(prevSongTimer === null) {
                    setPrevAction('restart');
                }
            }
            if(currentSong.getAttribute('src') === '' || currentSong.currentTime === currentSong.duration) {
                props.nextSong(1);
            }
        }
    }

    const resetUpdateTimer = () => {
        if(updateTimer) {
            clearInterval(updateTimer);
        }
        setUpdateTimer(setInterval(updateProgress, 50));
    }

    const loadNewPlaylist = () => {
        var currentSong = document.getElementById("current-song");
        props.setLoadingSongs(false);
        updateSongInfo();
        resetUpdateTimer();
        if(currentSong) {
            currentSong.play();
        }
    }

    useEffect(() => {
        resetUpdateTimer();
    }, []);

    useEffect(() => {
        props.setLoadingSongs(true);
        setTimeout(loadNewPlaylist, 1000);
    }, [props.songQueue]);

    useEffect(() => {
        setCurrTime(0);
        setCurrDuration(0);
        setCurrProgress(0);
        var currentSong = document.getElementById("current-song");
        if(currentSong !== undefined && currentSong !== null){
            currentSong.pause();
            currentSong.currentTime = 0;
            var as = document.getElementsByTagName("audio");
            if(props.currentSong < as.length){
                var nextSong = as[props.currentSong];
                nextSong.load();
                nextSong.play();
                if(playpause === "pause"){
                    currentSong.setAttribute("id", "");
                    setToPlaying();
                }
                updateSongInfo();
            } else {
                setToPaused();
                props.nextSong(-1);
            }
        }
    }, [props.currentSong]);

    useEffect(() => {
        if(props.setToPlay) {
            setToPlaying();
            props.clearSetToPlay();
        }
    }, [props.setToPlay]);

    useEffect(() => {
        if(props.setToPause) {
            setToPaused();
            props.clearSetToPause();
        }
    }, [props.setToPause]);

    useEffect(() => {
        if(props.togglePlayPause) {
            var a = document.getElementById("current-song");
            if(a) {
                if(playpause === 'play') {
                    setToPlaying();
                    a.play();
                } else {
                    setToPaused();
                    a.pause();
                }
            }
            props.clearTogglePlayPause();
        }
    }, [props.togglePlayPause]);

    const onPrevSong = (e) => {
        e.preventDefault();
        if(prevAction === "restart"){
            var a = document.getElementById("current-song");
            a.currentTime = 0;
            startPrevSongTimer();
        } else {
            props.nextSong(-1);
        }
    }

    const startPrevSongTimer = () => {
        if(prevSongTimer !== null){
            clearTimeout(prevSongTimer);
        }
        setPrevSongTimer(setTimeout(clearPrevSongTimer, 2000));
        setPrevAction('previous');
    }

    const clearPrevSongTimer = () => {
        setPrevAction('restart');
        setPrevSongTimer(null);
    }

    const setToPlaying = () => {
        setPlayPause('pause');
        setPlayButton(icons.pause);
    }

    const setToPaused = () => {
        setPlayPause('play');
        setPlayButton(icons.play);
    }

    const onPlayPause = (e) => {
        e.preventDefault();
        var a = document.getElementById("current-song");
        if(playpause === "pause") {
            setToPaused();
            a.pause();
        } else {
            setToPlaying();
            a.play();
        }
    }

    const beginScan = (e) => {
        e.preventDefault();
        props.beginScanSong(e);
    }

    return (
        <> {(!props.songQueue || !props.albumInfo) ? <></> :
            <div className="row fixed-bottom bg-light border p-0">
                <div id='progress-slider'
                     className="progress col-12"
                     style={{ height: "5px", overflow: "visible" }}
                     onMouseDown={beginScan}
                     >
                    <div className="progress-bar bg-success" role="progressbar" style={{ transitionDuration: "50ms", width: currProgress + "%" }} aria-valuenow={currProgress} aria-valuemin="0" aria-valuemax="100"></div>
                    <div className="rounded-circle bg-success" style={{height: "10px", width: "10px", margin: "-2.5px 0px 0px -2.5px"}}></div>
                </div>
                <div className="col-12">
                    <div className="d-flex flex-row text-muted" style={{ fontSize: "0.65rem" }}>
                        <div className="flex-fill mx-2">
                            {timeToMMSS(currTime)}
                        </div>
                        <div className="flex-fill text-end mx-2">
                            {timeToMMSS(currDuration)}
                        </div>
                    </div>
                </div>
                <div className="col-12 my-2">
                    <div className="row align-items-center">
                        <div className="col-12 col-sm-4 text-start">
                            <div className="fw-bold" style={{ fontSize: "0.9rem" }}>
                                {currSongName}
                            </div>
                            <div className="text-muted" style={{ fontSize: "0.75rem" }} >
                                    {currArtist} - {currAlbum}
                                </div>
                            </div>
                        <div className="col-12 col-sm-4 text-nowrap text-center">
                            {props.currentSong === 'loading' ? <></> :
                                props.songQueue.map((s, i, a) => (
                                    <AudioItem key={i}
                                               isCurrentSong={i === props.currentSong ? true : false}
                                               source={`${routes.static_base}/${s.stream_url}`}
                                               streamable={s.streamable}
                                               />
                                ))
                            }
                            <button type="button"
                                    className="btn btn-sm rounded-circle p-3"
                                    onClick={onPrevSong}>
                                    <img style={{ height: "20px", width: "20px" }} alt="previous" src={icons.prev} />
                            </button>
                            <button type="button"
                                    className="btn btn-success rounded-circle p-3 mx-2"
                                    onClick={onPlayPause}>
                                    <img style={{ height: "28px", width: "28px" }} alt={playpause} src={playButton} />
                            </button>
                            <button type="button"
                                    className="btn btn-sm rounded-circle p-3"
                                    onClick={(e) => {e.preventDefault(); props.nextSong(1)}}>
                                    <img style={{ height: "20px", width: "20px" }} alt="next" src={icons.next} />
                            </button>
                        </div>
                        <div className="col-12 col-sm-4 text-end">
                            <div className="d-flex flex-row align-items-center">
                                <VolumeSlider />
                                <div className='flex-fill text-center'>
                                    <button className='btn btn-sm btn-success'
                                            onClick={props.toggleQueue}
                                            >
                                        QUEUE
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}
